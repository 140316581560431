import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import * as echarts from 'echarts'
import COS from 'cos-js-sdk-v5'
import { IconsPlugin } from 'bootstrap-vue'

// Waypoint plugin
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import VuePageTransition from 'vue-page-transition'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import VueCookies from 'vue-cookies'
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/idea.css'

Vue.use(VueHighlightJS)
Vue.use(VueCookies)
Vue.use(mavonEditor)
Vue.use(VuePageTransition)
// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.prototype.$echarts = echarts
const cos = new COS({
  SecretId: 'AKIDjIdjZ9brtgEWaNvDG3bv0rSntqY0C9qH',
  SecretKey: 'C9rKYWGkywf8LJ1UheMp20iCPM4WieKk'
})
export default cos
const instance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
instance.interceptors.request.use(
  config => {
    config.data = qs.stringify(config.data) // 转为formdata数据格式
    return config
  },
  error => Promise.error(error)
)
axios.defaults.baseURL = 'https://mooc.kirinnorine.top/student'
axios.defaults.withCredentials = true
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
