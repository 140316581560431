import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fromPath: '',
    count: 1
  },
  mutations: {
    increment (state, n) {
      state.count = n
    }
  },
  actions: {}
})
