import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'
import axios from 'axios'
// Progress 进度条样式

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['@/views/Home'], resolve)
  },
  {
    path: '/myBlogs/:authorId',
    name: 'MyBlogs',
    component: resolve => require(['@/views/Blog/MyBlogs'], resolve)
  },
  {
    path: '/ourTeam',
    name: 'OurTeam',
    component: resolve => require(['@/views/OurTeam'], resolve)
  },
  {
    path: '/blogDetails/:blogId',
    name: 'BlogDetails',
    component: resolve => require(['@/views/Blog/BlogDetails'], resolve)
  },
  {
    path: '/blogEdit/:blogId',
    name: 'BlogEdit',
    component: resolve => require(['@/views/Blog/BlogEdit'], resolve)
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: resolve => require(['@/views/Blog/Blogs'], resolve)
  },
  {
    path: '/exploreCourse',
    name: 'Courses',
    component: resolve => require(['@/views/Courses'], resolve)
  },
  {
    path: '/mypage',
    name: 'StudentPage',
    component: resolve => require(['@/views/StudentPage'], resolve)
  },
  {
    path: '/login',
    name: 'Login',
    component: resolve => require(['@/views/Login'], resolve),
    meta: { transition: 'fade' }
  },
  {
    path: '/index',
    name: 'Home',
    redirect: ''
  },
  {
    path: '/exam',
    name: 'Exam',
    component: resolve => require(['@/views/ExamPaper'], resolve)
  },
  {
    path: '/finishedExam',
    name: 'FinishedExam',
    component: resolve => require(['@/views/FinishedPaper'], resolve)
  },
  {
    path: '/courseMain/:termId',
    name: 'CourseMain',
    component: resolve => require(['@/views/CourseMain'], resolve),
    children: [
      {
        path: '/statistics/:termId',
        name: 'Statistics',
        component: resolve => require(['@/components/CourseMain/Statistics'], resolve)
      },
      {
        path: '/discussionDetail/:id',
        name: 'DiscussionDetails',
        component: resolve => require(['@/components/CourseMain/DiscussionDetails'], resolve)
      },
      {
        path: '/studyFiles/:termId',
        name: 'StudyFiles',
        component: resolve => require(['@/components/CourseMain/StudyFiles'], resolve)
      },
      {
        path: '/courseMessage/:termId',
        name: 'CourseMessage',
        component: resolve => require(['@/components/CourseMain/CourseMessage'], resolve)
      },
      {
        path: '/discussion/:termId',
        name: 'AllComment',
        component: resolve => require(['@/components/CourseMain/AllComment'], resolve)
      },
      {
        path: '/mutualEvaluation/:termId',
        name: 'MutualEvaluation',
        component: resolve => require(['@/components/CourseMain/MutualEvaluation'], resolve)
      },
      {
        path: '/courseList/:termId',
        name: 'CourseList',
        component: resolve => require(['@/views/CourseList'], resolve)
      },
      {
        path: '/exams/:termId',
        name: 'AllMyExams',
        component: resolve => require(['@/components/CourseMain/AllMyExams'], resolve)
      }
    ]
  },
  {
    path: '/course',
    name: 'Course',
    component: resolve => require(['@/components/CourseMain/Course'], resolve)
  },
  {
    path: '/filesscanner',
    component: resolve => require(['@/components/FilesScanner'], resolve)
  },
  {
    path: '/register',
    name: 'Register',
    component: resolve => require(['@/views/Register'], resolve),
    children: [
      {
        path: '/step1',
        component: resolve => require(['@/components/RegisterSteps/RegisterStepOne'], resolve)
      },
      {
        path: '/step2',
        component: resolve => require(['@/components/RegisterSteps/RegisterStepTwo'], resolve)
      },
      {
        path: '/step3',
        component: resolve => require(['@/components/RegisterSteps/RegisterStepThree'], resolve)
      },
      {
        path: '/step4',
        component: resolve => require(['@/components/RegisterSteps/RegisterStepFour'], resolve)
      },
      {
        path: '',
        redirect: '/step1'
      }
    ]
  },
  {
    path: '*',
    component: resolve => require(['@/views/ErrorPage'], resolve)
  }
]
const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
const whiteList = ['/', '/login', '/register', '/blogs', '/ourTeam', '/step1', '/step2', '/step3', '/step4', '/exploreCourse']
router.beforeEach((to, from, next) => {
  NProgress.start()
  const fromPath = from.path
  const toPath = to.path
  axios({
    methods: 'GET',
    url: 'https://mooc.kirinnorine.top/student/checkLogin'
  }).then(res => {
    if (!res.data.status && !isWhitePath(toPath)) {
      router.push({
        name: 'Login',
        params: {
          fromPath: fromPath
        }
      })
      NProgress.done()
    }
  })
  if (fromPath.indexOf('/step') > -1) {
    const fromIndex = fromPath.substring(5)
    if (toPath.indexOf('/step') > -1) {
      const toIndex = toPath.substring(5)
      if (parseInt(toIndex) - parseInt(fromIndex) === 1) {
        next()
      } else {
        router.replace(fromPath)
      }
    }
  }
  next()
})
router.afterEach(() => {
  NProgress.done() // 结束Progress
})

function isWhitePath (path) {
  let flag = false
  whiteList.forEach(p => {
    if (p === path) {
      flag = true
    }
  })
  return flag
}

export default router
