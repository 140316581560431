<template>
  <div id="app">
    <vue-page-transition name="fade-in-down">
      <router-view/>
    </vue-page-transition>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
  mounted () {
    console.log('student:', this.$store.state.student)
  }
}
</script>

<style>
#app {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 2;
}
</style>
